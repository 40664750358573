export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATENŢIE: fără codul unic de retur, coletul nu poate fi identificat."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul contului bancar începe cu %s (exemplu: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că includeți numărul unic de reclamație cu produsul în pachet. Numărul dvs. unic de reclamație este"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produs deteriorat"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutări"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informaţiile nu sunt necesare"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici un motiv"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odată ce returnarea dvs. a fost primită la birourile noastre, un e-mail va fi trimis la adresa introdusă în formularul de reclamație, unde vă vom informa despre cele mai recente actualizări ale procesului de returnare."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul comenzii"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plătit pe site-ul nostru, și nu cu serviciul de livrare/curier"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calitatea produselor"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuirea produsului"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura de returnare"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesul de returnare produs"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivul plângerii"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restituire"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular de rambursare"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returnarea produsului este rapidă și ușoară"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returnarea produsului este rapidă și ușoară"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați tipul de reclamație"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți coletul la"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți coletul la următoarea adresă:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceva a mers prost, încercați din nou mai târziu sau contactați asistența noastră"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite formularul"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul contului bancar începe cu %s (exemplu: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura de returnare a fost transmisă la adresa dvs. de e-mail:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Împreună cu produsul care urmează să fie returnat, introduceți codul dvs. unic de retur în pachet.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul plății"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Când primim pachetul în sucursala noastră, veți fi informat cu privire la cursul plângerii la adresa dvs. de e-mail, pe care ați furnizat-o la depunerea formularului de reclamație"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produs greșit"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantitate greșită"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți imprima informații deja completate pentru a ajuta la returnarea produsului"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați trimis deja o cerere de returnare. Fii răbdător"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați primit un fișier PDF pentru procedura de returnare în atașare."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul dvs. unic de retur este"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oraș"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numărul reclamației"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplu"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucțiuni"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod poştal"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recidivă"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditor"])}
}